<template>
  <layout-wrapper :is-footer="false" class="bg-light-grey">

    <client-only>
      <full-page-loader :loading="loading" class="bg-light-grey opacity-50"/>
    </client-only>
    <div class="custom-container py-5">
      <bread-crumbs :items="breadCrumbsArray" class="sm:custom-container hidden sm:block"/>
      <div class="custom-container mt-3 flex gap-5">
        <div class="hidden sm:block w-[250px] lg:w-[200px] xl:w-[250px] me-3">
          <filters v-if="data?.filters" v-model:page="page" v-model:products="products" :filters="data?.filters"/>
          <meta-description :meta="data?.data"/>
        </div>
        <div class="w-full">
          <div v-if="!!brandsCustomData" class="grid grid-cols-2 bg-white rounded mb-3">
            <niceone-image :src="$publicPath(brandsCustomData?.image)"
                           class="w-full ltr:rounded-l rtl:rounded-r"/>
            <div class="flex flex-col items-center justify-center gap-3 p-3">
              <div class="font-bold text-lg">{{ brandsCustomData?.[`title_${$i18n.locale}`] }}</div>
              <div class="text-center">{{ brandsCustomData?.[`description_${$i18n.locale}`] }}</div>
            </div>
          </div>
          <template v-if="getProducts?.length">
            <div id="category-header" class="pb-6 flex items-center justify-between">
              <h1 class="font-niceone-medium text-xl" v-html="getPageTitle"></h1>
              <select-field v-model="sortOption" :block="false" :options="productSortOptions()"
                            :placeholder="$t('product.reviews.please_select_option')"/>
            </div>
            <div class="grid gap-3 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2">
              <product v-for="(product, index) in data?.products" :key="`product-${index}`" :product="product"
                       :source-data="setEventsData(product)" @productClicked="productClicked(product,index)"/>

            </div>
          </template>

          <category-not-found :image-width="150" :loading="loading" :products="getProducts"/>

          <div v-if="!loading && products?.length" class="flex-center py-5">
            <custom-pagination v-model="page" :total-items="total" @change="onPageUpdate"/>
          </div>

        </div>
      </div>
    </div>
  </layout-wrapper>
</template>

<script setup>
import Product from "@/components/Product";
import SelectField from "@/components/form/SelectField";
import {productSortOptions} from "@/helper/type";
import filters from "@/components/category/desktop/Filters"
import MetaDescription from "@/components/category/desktop/MetaDescription"
import BreadCrumbs from "~/components/common/BreadCrumbs.vue";
import {TRACKERS, EVENT_SOURCES} from "~/constants/events";
import {track} from "@/composables/useTrackEvent";
import CategoryJsonld from "@/jsonld/category";
import CategoryHead from "@/head/category";
import CustomPagination from "@/components/common/Pagination"
import CategoryNotFound from "@/components/category/NotFound"
import {useCustomFetch} from "@/composables/useFetch";
import {BLACKLISTED_BRANDS_SLUG} from "~/constants";
import {BRANDS_DATA_BY_SLUG} from "~/constants/brands";

const {t} = useI18n()

const route = useRoute()

const getSeoUrl = computed(() => {
  const slugs = route.params?.slugs?.filter(Boolean) || []
  return slugs?.length ? slugs[slugs.length - 1] : ''
})

const sortOption = ref(route?.query?.sort || 'most_popular')
const getManufacturerIds = computed(() => route?.query?.manufacturers || '')
const getAttributeIds = computed(() => route?.query?.filters || '')
const getSearch = computed(() => route?.query?.q || '')
const page = ref(Number(route.query?.page) || 1)


const {data, loading} = await useCustomFetch('?route=rest/product_admin/products', {
  seo_url: getSeoUrl,
  sort: sortOption,
  manufacturer_ids: getManufacturerIds,
  attribute_ids: getAttributeIds,
  search: getSearch,
  page,
  limit: 30,
  first: false
})

const categoryHierarchy = computed(() => data.value?.category_hierarchy)
const products = computed(() => data.value?.products)
const total = computed(() => data.value?.total)
const metaData = computed(() => data.value?.data)

const getPageTitle = computed(() => data.value?.category || getSearch.value || data.value?.manufcaturer)

const getProducts = computed(() => {
  if (BLACKLISTED_BRANDS_SLUG.includes(getSeoUrl.value)) return []
  return products.value
})

const brandsCustomData = computed(() => BRANDS_DATA_BY_SLUG[getSeoUrl.value])

// Head schema
useHead({
  titleTemplate: (title) => title ? `${title} | ${t('seo.niceone_ksa')}` : t('seo.category_title_template', {name: getPageTitle.value}),
  title: () => data.value?.data?.meta_title,
  ...CategoryHead({data: data.value?.data, products: data.value?.products, title: getPageTitle.value})
})

// Json schema
useJsonld(CategoryJsonld({title: getPageTitle.value, products: products.value}))

const onPageUpdate = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  products.value = []
  router.push({
    path: route.path,
    query: {...route.query, page: page.value},
  })
  track(TRACKERS.MORE_PRODUCTS, {
    eventAction: getPageTitle.value,
    eventLabel: page.value
  })
}

const router = useRouter()
const breadCrumbsArray = computed(() => {
  if (!categoryHierarchy.value || !categoryHierarchy.value?.length) return [];
  return categoryHierarchy.value?.map(val => ({
    title: val.category_name,
    link: val.seo_url
  }));
});

onMounted(() => {
  track(TRACKERS.OPEN_PRODUCT_LIST, {meta: metaData, page: page.value})
})

const productClicked = (product, index) => {
  //track(TRACKERS.FILTER_INTERACTIONS, {eventAction: '', filters: data.filters})
  track(TRACKERS.PLP_INTERACTION, {eventAction: '', eventLabel: product.name, product: product})
  if (getSearch.value) {
    track(TRACKERS.ALGOLIA_PRODUCT_CLICK, {id: product.id, queryID: product.queryID, index})
  }
}
watch(sortOption, (newSortOption, oldSortOption) => {
  track(TRACKERS.PRODUCT_SORTING, newSortOption)
});

watch(getSearch, () => {
  page.value = 1
})


const setEventsData = (product) => {
  if (metaData.value?.type == 'manufacturer') {
    return {...EVENT_SOURCES.BRAND_PAGE, source_name: metaData.value?.en_name, source_id: metaData.value?.id}
  }
  if (!route.query.sort) {
    return {
      ...EVENT_SOURCES.CATEGORY_PAGE,
      source_name: getSeoUrl.value,
      source_id: product.category_hierarchy,
      category_hierarchy: true
    }
  } else {
    return {...EVENT_SOURCES.PRODUCT_LISTING_PAGE, source_name: metaData.value?.en_name, source_id: metaData.value?.id}
  }
}
</script>